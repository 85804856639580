import Image from "next/image";

const WidgetPostList = ({ postData, id, lastElement }) => {
  return (
    <div className={`axil-single-widget widget widget_postlist ${lastElement ? "" : "mb--30"}`} id={id}>
      <h5 className="widget-title" style={{ fontSize: 24 }}>XEM NHIỀU NHẤT</h5>
      <div className="post-medium-block">
        {postData.map((data, index) => (
          <div className={`content-block post-medium mb--${index === postData.length - 1 ? "0" : "20"}`} key={data.slug}>
            {data.featuredImage ?
              <div className="post-thumbnail">
                <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.featuredImage.node.altText} style={{ width: 120, height: 80, position: "relative" }}>
                  <Image
                    src={data.featuredImage.node.sourceUrl}
                    alt={data.featuredImage.node.altText ? data.featuredImage.node.altText : "Thumbnail"}
                    fill
                    sizes="100%"
                    priority={true}
                  />
                </a>
              </div>
              : ""}
            <div className="post-content">
              <h6 className="title">
                <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title}>{data.title}</a>
              </h6>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WidgetPostList;
