import Image from "next/image";
import Skeleton from 'react-loading-skeleton';
import Moment from "../../moment";

const PostLayoutThree = ({ dataPost }) => {
  return (
    <div>
      {
        dataPost[0] ? <>
          <div
            className="content-block post-list-view axil-control mt--30 position-relative"
          >
            <Image
              src="/images/icons/hot-news.svg"
              alt="Hot News"
              width="150"
              height="50"
              style={{ width: 150, height: 50 }}
              placeholder="blur"
              blurDataURL="images/placeholder.png"
            />
            <div className="d-none d-md-block mt--10">
              <div className="row">
                <div className="col-6">
                  {dataPost[0].featuredImage ?
                    <div className="post-thumbnail">
                      <a className="position-relative" href={`/${dataPost[0].categories.nodes[0].slug}/${dataPost[0].slug}`} aria-label={dataPost[0].title}>
                        <Image
                          src={dataPost[0].featuredImage.node.sourceUrl}
                          alt={dataPost[0].featuredImage.node.altText ? dataPost[0].featuredImage.node.altText : "Thumbnail"}
                          fill
                          sizes="100%"
                          priority={true}
                          placeholder="blur"
                          blurDataURL="images/placeholder.png"
                        />
                      </a>
                    </div>
                    : ""}
                </div>
                <div className="col-6">
                  <div className="post-content mt--15">
                    <h4 className="title">
                      <a aria-label={dataPost[0].title} href={`/${dataPost[0].categories.nodes[0].slug}/${dataPost[0].slug}`}>
                        {dataPost[0].title}
                      </a>
                    </h4>
                    <p className="b3 content mt--15 mb--5 unline-clamp">
                      {dataPost[0].seo.opengraphDescription}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-block d-md-none mt--10" style={{ flexBasis: '100%', maxWidth: 'unset' }}>
              <div className="content-block hot-list-view axil-control">
                <div className="row p--0 m--0">
                  <div className="col-12 p--0">
                    {dataPost[0].featuredImage ?
                      <div className="post-thumbnail p--0" style={{ maxWidth: 'unset', minHeight: 'unset', height: 200 }}>
                        <a href={`/${dataPost[0].categories.nodes[0].slug}/${dataPost[0].slug}`} className="position-relative" aria-label={dataPost[0].title}>
                          <Image
                            src={dataPost[0].featuredImage.node.sourceUrl}
                            alt={dataPost[0].featuredImage.node.altText ? dataPost[0].featuredImage.node.altText : " Thumbnails"}
                            fill
                            sizes="100%"
                            priority={true}
                            placeholder="blur"
                            blurDataURL="images/placeholder.png"
                          />
                        </a>
                      </div>
                      : ""}
                  </div>
                  <div className="col-12">
                    <div className="post-content p--0 mt--10">
                      <div className="content-block">
                        <div className="post-content p--0">
                          <h5 className="title unline-clamp">
                            <a href={`/${dataPost[0].categories.nodes[0].slug}/${dataPost[0].slug}`} aria-label={dataPost[0].title}>{dataPost[0].title}</a>
                          </h5>
                          <p className="b3 content mt--15 mb--5 unline-clamp ">
                            {dataPost[0].seo.opengraphDescription}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="post-content-sub mb--0 mt--10">
                {
                  dataPost.slice(1).map((data, index) => (
                    <li key={index} className="title line--clamp-4">
                      <h5 className="mt--10 mb--10" style={{ fontSize: 14 }}>
                        <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title}>
                          {data.title}
                        </a>
                      </h5>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div
            className="content-block post-list-view axil-control"
          >
            <div className="d-none d-md-block">
              <div className="row mt--15">
                {
                  dataPost.slice(1).map((data, index) => (
                    <div className="col-4" key={index}>
                      <div className="post-content">
                        <div className="title line--clamp-4">
                          {data.featuredImage ?
                            <div className="post-thumbnail sub">
                              <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} className="position-relative">
                                <Image
                                  src={data.featuredImage.node.sourceUrl}
                                  alt={data.featuredImage.node.altText ? data.featuredImage.node.altText : "Thumbnail"}
                                  fill
                                  sizes="100%"
                                  priority={true}
                                  placeholder="blur"
                                  blurDataURL="images/placeholder.png"
                                />
                              </a>
                            </div>
                            : ""}
                          <ul className="post-meta-list m--0 mt--5">
                            <li className="d-flex align-items-center ml--0">
                              <Moment date={data.date} />
                            </li>
                          </ul>
                          <h5 style={{ fontSize: 16 }} className="m--0">
                            <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title}>
                              {data.title}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </> :
          <>
            <div className="content-block post-list-view axil-control mt--30 position-relative">
              <Image
                src="/images/icons/hot-news.svg"
                alt="Hot News"
                width="150"
                height="50"
                style={{ width: 150, height: 50 }}
                placeholder="blur"
                blurDataURL="images/placeholder.png"
              />
              <div className="d-none d-md-block mt--10">
                <div className="row">
                  <div className="col-6">
                    <div className="post-thumbnail">
                      <Skeleton height={200} />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="post-content mt--15">
                      <h4 className="title">
                        <Skeleton height={30} />
                      </h4>
                      <p className="b3 content mt--15 mb--5 unline-clamp">
                        <Skeleton count={2} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block d-md-none mt--10" style={{ flexBasis: '100%', maxWidth: 'unset' }}>
                <div className="content-block hot-list-view axil-control">
                  <div className="row w-100 p--0 m--0">
                    <div className="col-12 p--0">
                      <div className="post-thumbnail p--0" style={{ maxWidth: 'unset', minHeight: 'unset', height: 200 }}>
                        <a className=" position-relative">
                          <Image
                            src="/images/placeholder.png"
                            alt="Thumbnail"
                            fill
                            sizes="100%"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="post-content p--0 mt--10">
                        <div className="content-block">
                          <div className="post-content p--0">
                            <h5 className="title unline-clamp">
                              <Skeleton height={30} />
                            </h5>
                            <p className="b3 content mt--15 mb--5 unline-clamp ">
                              <Skeleton count={2} />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="post-content-sub mb--0 mt--10">
                  <li className="title line--clamp-4">
                    <h5 className="mt--10 mb--10" style={{ fontSize: 14 }}>
                      <Skeleton height={30} />
                    </h5>
                  </li>
                  <li className="title line--clamp-4">
                    <h5 className="mt--10 mb--10" style={{ fontSize: 14 }}>
                      <Skeleton height={30} />
                    </h5>
                  </li>
                  <li className="title line--clamp-4">
                    <h5 className="mt--10 mb--10" style={{ fontSize: 14 }}>
                      <Skeleton height={30} />
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="content-block post-list-view axil-control"
            >
              <div className="d-none d-md-block">
                <div className="row mt--15">
                  <div className="col-4">
                    <div className="post-content">
                      <div className="title line--clamp-4">
                        <div className="post-thumbnail sub">
                          <Skeleton height={200} />
                        </div>
                        <ul className="post-meta-list m--0 mt--5">
                          <li className="d-flex align-items-center ml--0">
                            <Skeleton height={20} />
                          </li>
                        </ul>
                        <h5 style={{ fontSize: 16 }} className="m--0">
                          <Skeleton height={30} />
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="post-content">
                      <div className="title line--clamp-4">
                        <div className="post-thumbnail sub">
                          <Skeleton height={200} />
                        </div>
                        <ul className="post-meta-list m--0 mt--5">
                          <li className="d-flex align-items-center ml--0">
                            <Skeleton height={20} />
                          </li>
                        </ul>
                        <h5 style={{ fontSize: 16 }} className="m--0">
                          <Skeleton height={30} />
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="post-content">
                      <div className="title line--clamp-4">
                        <div className="post-thumbnail sub">
                          <Skeleton height={200} />
                        </div>
                        <ul className="post-meta-list m--0 mt--5">
                          <li className="d-flex align-items-center ml--0">
                            <Skeleton height={20} />
                          </li>
                        </ul>
                        <h5 style={{ fontSize: 16 }} className="m--0">
                          <Skeleton height={30} />
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
      }
    </div>
  );
};

export default PostLayoutThree;
