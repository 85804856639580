import PostLayoutThree from "./layout/PostLayoutThree";
import PostLayoutFour from "./layout/PostLayoutFour";

const PostSectionFour = ({ postData, dataPost, page }) => {
  return (
    <div className={`axil-post-list-area post-listview-visible-color axil-section-gapTop ${ page === "home" ? "bg-color-gray pb--30 pl--30 pr--30 pt--20 mt--30 rounded" : page === "category" ? "pt--0" : "mb--40 bg-color-gray p--30 rounded"}`}>
      {
        page === "category" ? <PostLayoutFour dataPost={dataPost} postData={postData} show="5" /> : <PostLayoutThree dataPost={dataPost} show="5" />
      }
    </div>
  );
};

export default PostSectionFour;
