import { useEffect, useState } from "react"

const Moment = ({ date }) => {
  const [moment, setMoment] = useState(date);

  useEffect(() => {
    const time = new Date(date).getTime();
    const now = new Date().getTime();
    const diff = now - time;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const mins = Math.floor(diff / (1000 * 60));
    const secs = Math.floor(diff / 1000);
    if (secs < 60) {
      setMoment(`${secs} giây trước`);
    } else if (mins < 60) {
      setMoment(`${mins} phút trước`);
    } else if (hours < 24) {
      setMoment(`${hours} giờ trước`);
    } else if (days < 30) {
      setMoment(`${days} ngày trước`);
    }
  }, []);

  if(!moment) return null;

  return <span>{moment}</span>
}

export default Moment;