import Image from "next/image";
// import Skeleton from 'react-loading-skeleton';
import Moment from "../../moment";

const PostLayoutTwo = ({ dataPost, bgColor, start, end, isMobile }) => {

  const posts = start ? dataPost.props.posts.slice(start) : dataPost.props.posts;

  return (
    <>
      <div className="d-none d-sm-block">
        {posts.length > 0 ?
          posts.slice(start !== undefined ? 0 : 4, end ? end : posts.length).map((data, index) => (
            <div
              className={`content-block hot-list-view axil-control ${index !== 0 || !end ? "mt--40" : ""}  ${bgColor || ""}`}
              //hot-post-style
              key={data.slug}
            >
              {data.featuredImage ?
                <div className="post-thumbnail">
                  <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title} className="position-relative">
                    <Image
                      src={data.featuredImage.node.sourceUrl}
                      alt={data.featuredImage.node.altText ? data.featuredImage.node.altText : "Thumbnail"}
                      fill
                      sizes="100%"
                      priority={true}
                      placeholder="blur"
                      blurDataURL="images/placeholder.png"
                    />
                  </a>
                </div>
                : ""}

              <div className="post-content">
                <ul className="post-meta-list">
                  {
                    data.categories.nodes.length > 0 && data.categories.nodes[0].parentId !== null &&
                    <li className="li-more">
                      <div className="post-cat-more">
                        <a href={`/chuyen-muc/${data.categories.nodes[0].slug}`} aria-label={data.categories.nodes[0].name}>
                          <span data-text={data.categories.nodes[0].name}>{data.categories.nodes[0].name}</span>
                        </a>
                      </div>
                    </li>
                  }
                  <li className="d-flex align-items-center li-more">
                    <Moment date={data.date} />
                  </li>
                </ul>
                <h5 className="title">
                  <a href={`/${data.categories.nodes[0]?.slug}/${data.slug}`} aria-label={data.title}>{data.title}</a>
                </h5>
                <div className="mt--10">
                  <p className="b2 content">
                    {data.seo.opengraphDescription}
                  </p>
                </div>
              </div>
            </div>
          )) : null
          // <>
          //   {
          //     [...Array(4)].map((_, index) => (
          //       <div
          //         className={`content-block hot-list-view axil-control ${index !== 0 || !end ? "mt--40" : ""}  ${bgColor || ""}`}
          //         //hot-post-style
          //         key={index}
          //       >
          //         <div className="post-thumbnail">
          //           <Skeleton height={160} />
          //         </div>
          //         <div className="post-content">
          //           <ul className="post-meta-list m--0">
          //             <li className="li-more">
          //               <div className="post-cat-more mr--5">
          //                 <Skeleton width={100} />
          //               </div>
          //             </li>
          //             <li className="d-flex align-items-center li-more">
          //               <Skeleton width={100} />
          //             </li>
          //           </ul>
          //           <h5 className="title">
          //             <Skeleton count={2} />
          //           </h5>
          //           <div className="mt--10">
          //             <Skeleton count={3} />
          //           </div>
          //         </div>
          //       </div>
          //     ))
          //   }
          // </>
        }
      </div>
      <div className="d-block d-sm-none">
        {posts.slice(start !== undefined ? 0 : 4, end ? end : posts.length).map((data, index) => (
          <div key={data.slug}>
            {
              // check if half of posts
              index === Math.floor(posts.length / 2) && isMobile && <div id="mb-inflow"></div>
            }
            <div
              className={`content-block hot-list-view axil-control parent mt--15 ${index !== 0 && "pt--15"} ${bgColor || ""}`}
            >
              <div className="post-content pt--0 pl--0">
                <h5 className="title">
                  <a href={`/${data.categories.nodes[0]?.slug}/${data.slug}`} aria-label={data.title}>{data.title}</a>
                </h5>
                <ul className="post-meta-list">
                  {
                    data.categories.nodes.length > 0 && data.categories.nodes[0].parentId !== null &&
                    <li className="li-more">
                      <div className="post-cat-more">
                        <a href={`/chuyen-muc/${data.categories.nodes[0].slug}`} aria-label={data.categories.nodes[0].name}>
                          <span data-text={data.categories.nodes[0].name}>{data.categories.nodes[0].name}</span>
                        </a>
                      </div>
                    </li>
                  }
                  <li className="d-flex align-items-center li-more">
                    <Moment date={data.date} />
                  </li>
                </ul>
              </div>
              <div
                className={`content-block hot-list-view axil-control ${bgColor || ""}`}
                key={data.slug}
              >
                {data.featuredImage ?
                  <div className="post-thumbnail">
                    <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title} className="position-relative">
                      <Image
                        src={data.featuredImage.node.sourceUrl}
                        alt={data.featuredImage.node.alt ? data.featuredImage.node.alt : "Thumbnail"}
                        fill
                        sizes="100%"
                        priority={true}
                        placeholder="blur"
                        blurDataURL="images/placeholder.png"
                      />
                    </a>
                  </div>
                  : ""}

                <div className="post-content" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <div className="content-block">
                    <div className="post-content">
                      <p className="b2 content">
                        {data.seo.opengraphDescription}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PostLayoutTwo;
