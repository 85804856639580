import Image from "next/image";
import Moment from "../../moment";

const PostLayoutFour = ({ dataPost, postData }) => {

  return (
    <>
      {
        dataPost[0] && <>
          <div
            className="content-block post-list-view axil-control mt--30"
            key={postData[0].slug}
          >
            <div className="row" style={{ marginLeft: -16, marginRight: -16 }}>
              <div className="col-xl-6 col-lg-6 col-md-7 col-12" style={{ paddingLeft: 16, paddingRight: 16 }}>
                {postData[0].featuredImage ?
                  <div className="post-content">
                    <div className="post-thumbnail category">

                      <a href={`/${postData[0].categories.nodes[0].slug}/${postData[0].slug}`} aria-label={postData[0].title} className="position-relative">
                        <div className="position-absolute top-0 left-0 w-100 h-100" style={{ backgroundImage: 'linear-gradient(transparent 55%, rgba(0,0,0,0.9))', zIndex: 1, cursor: "pointer", borderRadius: 10 }}></div>
                        <Image
                          src={postData[0].featuredImage.node.sourceUrl}
                          alt={postData[0].featuredImage.node.altText ? postData[0].featuredImage.node.altText : "Thumbnail"}
                          fill
                          sizes="100%"
                          priority={true}
                          placeholder="blur"
                          blurDataURL="images/placeholder.png"
                        />
                      </a>
                      
                      <div className="post-cat position-absolute bottom-0 pl--20 pr--20 pb--20" style={{ zIndex: 2 }}>
                        <h4 className="title  mb--10 unline-clamp typography-color-white">
                          <a href={`/${postData[0].categories.nodes[0].slug}/${postData[0].slug}`} aria-label={postData[0].title}>
                            {postData[0].title}
                          </a>
                        </h4>
                        <ul className="post-meta-list mt--10 ">
                          {
                            postData[0].categories.nodes.length > 0 && postData[0].categories.nodes[0].parentId !== null &&
                            <li className="li-more typography-color-white">
                              <div className="post-cat-more">
                                <a href={`/chuyen-muc/${postData[0].categories.nodes[0].slug}`} aria-label={postData[0].categories.nodes[0].name}>
                                  <span data-text={postData[0].categories.nodes[0].name}>{postData[0].categories.nodes[0].name}</span>
                                </a>
                              </div>
                            </li>
                          }
                          <li className="d-flex align-items-center li-more typography-color-white">
                            <Moment date={postData[0].date} />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  : ""}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-5 col-12 mt_sm--20" style={{ paddingLeft: 16, paddingRight: 16 }}>
                {
                  postData[1] ? <>

                    <div className="post-content">
                      <div className="content-block d-flex mb--10">
                        {postData[1]?.featuredImage ?
                          <div className="post-thumbnail category-sub m--0 p--0">
                            <a aria-label={postData[1].title} className="position-relative" href={`/${postData[1].categories.nodes[0].slug}/${postData[1].slug}`}>
                              <Image
                                src={postData[1].featuredImage.node.sourceUrl}
                                alt={postData[1].featuredImage.node.alt ? postData[1].featuredImage.node.alt : "Thumbnail"}
                                fill
                                sizes="100%"
                                priority={true}
                                placeholder="blur"
                                blurDataURL="images/placeholder.png"
                              />
                            </a>
                          </div>
                          : ""}
                      </div>
                      <ul className="post-meta-list mt--10 ">
                        {
                          postData[1]?.categories.nodes.length > 0 && postData[1]?.categories.nodes[0].parentId !== null &&
                          <li className="li-more">
                            <div className="post-cat-more">
                              <a >
                                <span data-text={postData[1].categories.nodes[0].name}>{postData[1].categories.nodes[0].name}</span>
                              </a>
                            </div>
                          </li>
                        }
                        <li className="d-flex align-items-center li-more ">
                          <Moment date={postData[1]?.date} />
                        </li>
                      </ul>
                      <h5 className="title  mb--10" style={{ fontSize: 19 }}>
                        <a aria-label={postData[1]?.title} href={`/${postData[1]?.categories?.nodes[0]?.slug}/${postData[1]?.slug}`}>
                          {postData[1]?.title}
                        </a>
                      </h5>
                      <p className="b3 content mb--5 ">
                        {postData[1]?.seo.opengraphDescription}
                      </p>
                    </div>
                    <ul className="post-meta-list ml--0 mr--0 mt--15 pt--10 border-top ">
                      <li className="d-flex align-items-center ml--0 ">
                        Tin liên quan
                      </li>
                    </ul>
                    <ul className="post-content-sub">
                      {
                        dataPost.slice(1).map((data) => (
                          <li key={data.slug} className="title line--clamp-4">
                            <h5 className="mt--10 mb--10 " style={{ fontSize: 14 }}>
                              <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title}>
                                {data.title}
                              </a>
                            </h5>
                          </li>
                        ))
                      }
                    </ul>
                  </> : ""
                }
              </div>
            </div>
          </div>
        </>
      }
      {/* ))} */}
    </>
  );
};

export default PostLayoutFour;
